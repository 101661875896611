import React from "react"
import "./Contact.scss"
import PropTypes from "prop-types"

export default function Contact({ logo, title, subtitle, breakWord = false }) {
  const style = breakWord ? { wordBreak: "break-all" } : {}
  return (
    <div className="contact-tile is-flex   ">
      <div className="contact-tile__icon">
        <img src={`/${logo}`} alt={title} />
      </div>
      <div className="contact-tile__information">
        <div className="contact-tile__information-title">{title}</div>
        <div style={style} className="contact-tile__information-subtitle">
          {subtitle}
        </div>
      </div>
    </div>
  )
}

Contact.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any.isRequired,
}
