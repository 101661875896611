import React from "react"
import "./ContactPage.scss"
import Layout from "../layout"
import Contact from "./Contact/Contact"

const contactInformation = [
  // {
  //   logo: "phone.svg",
  //   title: "Phone number",
  //   subtitle: "961 78 969 767",
  // },
  {
    logo: "email.svg",
    title: "Email us on",
    subtitle: (
      <a href="mailto:support@cloudgate.consulting">
        support@cloudgate.consulting
      </a>
    ),
  },
  {
    logo: "location.svg",
    title: "Dubai, U.A.E",
    subtitle:
      "Business Center Logistic City,Dubai Aviation City,P.O.Box: 390667,Dubai, U.A.E.",
  },
  {
    logo: "location.svg",
    title: "Beirut, Lebanon",
    subtitle:
      "Damascus International Highway, OPEL Showroom building, First Floor, Hazmieh, Beirut, Lebanon",
  },
]

export default function ContactPage() {
  return (
    <Layout>
      <section className="section contact-section">
        <div className="container">
          <div className="contact-section__header">
            <div className="contact-section__header-title">
              <h1 className="title"> Contact our Support Team</h1>
            </div>
            <div className="contact-section__header-subtitle">
              <h2 className="subtitle">
                Need to get in touch ? We are all ears
              </h2>
            </div>
          </div>
          <div className="contact-section__contact-information ">
            <div className="columns is-multiline">
              {contactInformation.map(({ logo, title, subtitle }, index) => {
                return (
                  <div
                    key={index}
                    className="column is-one-third-fullhd is-half-tablet"
                  >
                    <Contact
                      breakWord={index === 0}
                      logo={logo}
                      title={title}
                      subtitle={subtitle}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
